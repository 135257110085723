export default {
  props: {
    title: {
      type: Object,
    },
    body: {
      type: String,
    },
    items: {
      type: [Object, Array],
      required: true,
    },
    button: {
      type: Object,
    },
  },

  components: {
    hotAccordionToTab: () => import('~/components/Organisms/AccordionToTab/AccordionToTab.vue'),
  },

  computed: {
    hasTitle() {
      return this.title ? true : false
    },
    hasBody() {
      return this.body ? true : false
    },
    hasButton() {
      return this.button ? true : false
    },
  },
}
